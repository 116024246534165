import React from "react"
import { FaEnvelope, FaLinkedin, FaInstagram, FaTwitter } from 'react-icons/fa'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  socialMedia: {
    'font-size': '40px',
    'line-height': '40px',
  },
  icon: {
    'color': 'white',
    'margin': '10px 10px 0px 10px',
    '&:hover': {
      width: '50%',
      'border-bottom': '2px solid',
      background: 'transparent'
    }
  }
})

export default function SocialMedia() {
    const classes = useStyles()
    return (
        <div className={classes.socialMedia}>
            <a className={classes.icon} aria-label="Mail" href="mailto:me@antonioi.dev">
              <FaEnvelope />
            </a>
            <a className={classes.icon} aria-label="LinkedIn" href="https://uk.linkedin.com/in/antonioi">
              <FaLinkedin />
            </a>
        </div>
    )
}
