import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SocialMedia from "../components/socialMedia"
import { createUseStyles } from 'react-jss'
import SEO from "../components/seo"
import { GatsbyImage } from "gatsby-plugin-image"

const useStyles = createUseStyles({
    layout: {
        textAlign: 'center'
    },
    title: {
        fontSize: '25px',
        'font-weight': 'bolder',
        'margin': 0
    },
    link: {
        display: 'inline-block',
        fontSize: '13px',
        color: 'white',
        textDecoration: 'none',
        borderStyle: 'solid',
        padding: '10px',
        borderWidth: '1px',
        '&:hover ': {
            backgroundColor: 'white',
            color: '#02182B'
        }
    },
    list: {
        margin: `1rem`
    }
})

export default function Links({ data }) {
    const classes = useStyles()
    const metaData = data.site.siteMetadata
    return (
        <Layout style={classes.layout} showFooter={false}>
            <SEO title="Links" />
            <ul style={{ listStyle: `none`, width: '100%', margin: 0 }}>
                <li className={classes.list}>
                    <p className={classes.title}>{metaData.title}</p></li>
                <li className={classes.list}>
                    <GatsbyImage image={data.file.childImageSharp.gatsbyImageData} />
                </li>
                {data.allMarkdownRemark.edges.map(({ node }) => (
                    <li className={classes.list}>
                        <div key={node.id}>
                            <Link
                                className={classes.link}
                                to={node.fields.slug}
                            >
                                {"Current Article: "}{node.frontmatter.title}
                            </Link>
                        </div>
                    </li>
                ))}
                <li className={classes.list}><a className={classes.link} href="https://kit.co/antonioidev/my-setup">Current Setup</a></li>
                <li className={classes.list}><SocialMedia/></li>
            </ul>
        </Layout>
    )
}

export const query = graphql`{
  site {
    siteMetadata {
      title
    }
  }
  file(relativePath: {eq: "me.png"}) {
    childImageSharp {
      gatsbyImageData(width: 250, height: 250, placeholder: NONE, layout: FIXED)
    }
  }
  allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC}, limit: 1) {
    totalCount
    edges {
      node {
        id
        frontmatter {
          title
          date(formatString: "DD MMMM, YYYY")
          category
        }
        fields {
          slug
        }
        excerpt
      }
    }
  }
}
`
